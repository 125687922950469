Revolution.Module.extend({
    NAME: "mde.header", // OBLIGATOIRE (reflète l'arborescence répertoire : /mde/main.js)

    initialize: function ($) {
        $("header").headroom({
            "offset": 200,
            "tolerance": 0
        });
    },

    onWindowLoad: function (e) {
        //
    },
    onWindowScroll: function (e) {
        //
    },
    onWindowResize: function (e) {
        //
    }
});
