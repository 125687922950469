Revolution.Module.extend({
    NAME: "mde.bk-fake-select", // OBLIGATOIRE (reflète l'arborescence répertoire : /mde/main.js)

    initialize: function ($) {
        // appelé aussitôt la page chargée
        //console.log("module " + this.NAME);

        _.bindAll(this, "closeFakeSelect", "toggleFakeSelect");

        //Example to call an external function
        //var UTILS = Revolution.get("mde._utils");
        //UTILS.equalheight($('.bk-mosaique .js-equal-height'));
        $('body').on('click', '.fake-select > button', this.toggleFakeSelect);
        $('body').on('click', '.fake-select', function (e) {
            e.stopPropagation();
        });
        $('body').on('click', this.closeFakeSelect);
    },

    onWindowLoad: function (e) {
        //
    },
    onWindowScroll: function (e) {
        //
    },
    onWindowResize: function (e) {
        //
    },

    closeFakeSelect: function () {
        $('.fake-select > button').attr('aria-expanded', 'false').removeClass('is-open');
        $('.fake-select .fake-select-dropdown').slideUp('fast');
    },

    toggleFakeSelect: function (e) {
        var $obj = $(e.currentTarget);
        var $dropDown = $obj.closest('.fake-select').find('.fake-select-dropdown');
        if ($obj.hasClass('is-open')) {
            $dropDown.slideUp();
            $obj.attr('aria-expanded', false).removeClass('is-open');
        } else {
            this.closeFakeSelect();
            $dropDown.slideDown();
            $obj.attr('aria-expanded', true).addClass('is-open');
        }
    }
});