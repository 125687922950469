Revolution.Module.extend({
	NAME : "mde.contrast-fontsize", // OBLIGATOIRE (reflète l'arborescence répertoire : /mde/main.js)

	initialize : function ($) {
		// appelé aussitôt la page chargée
		//console.log("module " + this.NAME);

		//_.bindAll(this, "myfunction", "runEqualHeight", "getHeightsForOffsets");

		//Example to call an external function
		//var UTILS = Revolution.get("mde._utils");
		//UTILS.equalheight($('.bk-mosaique .js-equal-height'));

		//Contrast
		$('body').on('click', '.js-toggle-contrast', this.toggleContrast);


		if (Cookies.get('contrasted') == "true") {
			$('.js-toggle-contrast').click();
		}

		$('body').on('click', '.js-fz-btns button', this.changeFz);
		this.setFz();


	},

	onWindowLoad : function (e) {
		//
	},
	onWindowScroll : function (e) {
		//
	},
	onWindowResize : function (e) {
	    //
	},

	toggleContrast: function(e) {
	    //console.log('toggleContrast');
		var $obj = $(e.currentTarget);

		//if (Cookies.get('contrasted') == "true") {
		if ($('body').hasClass('contrasted-theme')) {
			$('body').removeClass('contrasted-theme');
			Cookies.set('contrasted', "false", { path: '/' });
			$obj.removeClass('active');
		} else {
			$('body').addClass('contrasted-theme');
			Cookies.set('contrasted', "true", { path: '/' });
			$obj.addClass('active');
		}
	},
	changeFz : function(e) {
        //console.log('changeFz');
		var $obj = $(e.currentTarget);
		var selector = $obj.closest('.js-fz-btns').data('selector');
		var $textContainer = $(selector);
		var max = parseInt($obj.closest('.js-fz-btns').data('max'));
		var min = parseInt($obj.closest('.js-fz-btns').data('min'));
		var defaultFz = parseInt($('body').css('font-size'));
		var currentSize = defaultFz;
		if (Cookies.get('fontsize')) {
			currentSize = parseInt(Cookies.get('fontsize'));
		}

		//console.log('currentSize : ', currentSize);

		if($obj.data('action') == 'decrease') {
			//console.log('descrease !');
			if (currentSize - 2 >= min) {
				currentSize = parseInt(currentSize) - 2;
				Cookies.set('fontsize', currentSize, { path: '/' });
				$textContainer.css('font-size', currentSize);
				$obj.closest('.js-fz-btns').find('[data-action="increase"]').css('opacity', '').removeAttr('disabled');
				if (currentSize == min) {
					//console.log('MIN');
					$obj.css('opacity', 0.5).attr('disabled', 'disabled');
				}
				//console.log(currentSize);
			}
		} else if ($obj.data('action') == 'reset') {
			//console.log('reset !');
			$textContainer.css('font-size', '');
			currentSize = defaultFz;
			Cookies.remove('fontsize', { path: '/' });
			$obj.closest('.js-fz-btns').find('button').css('opacity', '').removeAttr('disabled');
		} else if ($obj.data('action') == 'increase') {
			//console.log('increase !');
			if (currentSize + 2 <= max) {
				currentSize = parseInt(currentSize) + 2;
				Cookies.set('fontsize', currentSize, { path: '/' });
				$textContainer.css('font-size', currentSize);
				$obj.closest('.js-fz-btns').find('[data-action="decrease"]').css('opacity', '').removeAttr('disabled');
				if (currentSize == max) {
					//console.log('MAX');
					$obj.css('opacity', 0.5).attr('disabled', 'disabled');
				}
				//console.log(currentSize);
			}
		}

	},
	setFz : function() {
		if (Cookies.get('fontsize')) {
			var selector = $('.js-fz-btns').data('selector');
			var $textContainer = $(selector);
			var max = parseInt($('.js-fz-btns').data('max'));
			var min = parseInt($('.js-fz-btns').data('min'));
			var currentSize  = parseInt(Cookies.get('fontsize'));
			var $btnMinus = $('.js-fz-btns [data-action="decrease"]');
			var $btnPlus = $('.js-fz-btns [data-action="increase"]');

			if (currentSize == min) {
				$btnMinus.css('opacity', 0.5).attr('disabled', 'disabled');
			} else if (currentSize == max) {
				$btnPlus.css('opacity', 0.5).attr('disabled', 'disabled');
			}
			$textContainer.css('font-size', currentSize);
		}
	}


});
