Revolution.Module.extend({
    NAME: "mde.forms", // OBLIGATOIRE (reflète l'arborescence répertoire : /mde/main.js)

    initialize: function ($) {
        // appelé aussitôt la page chargée
        //console.log("module " + this.NAME);

        //_.bindAll(this, "closeNavMob", "showBtnExpendTouch");
        //

        //events
        this.initInputFile();

        $('.label-number').closest('label').prev().on('change', this.checkboxesCounter);
        $('.js-uncheck-options').on('click', this.uncheckOptions);

        $('body').on('change', '.js-conditional-radio', this.conditionnalRadio);

        $('body').on('click', '.js-add-form-line', this.addFormLine);
        $('body').on('click', '.js-remove-line', this.removeFormLine);

        $('.js-only-numbers').mask('0#');

        this.setDatePicker();
    },

    onWindowLoad: function (e) {

    },

    onWindowScroll: function (e) {

    },
    onWindowResize: function (e) {

    },

    removeFormLine: function (e) {
        var $obj = $(e.currentTarget);
        $obj.closest('.js-added-line').remove();
    },

    addFormLine: function (e) {
        var $obj = $(e.currentTarget);
        var $parent = $obj.closest('.js-add-form-line-wrapper');
        var templating = $parent.find('[type="text/template"]').html();
        var $wrapper = $parent.find('.js-insert-wrapper');
        var nblines = $wrapper.find('> *').length;
        var renderTemplate = templating.replace(/{i}/g, nblines + 1);

        $wrapper.append(renderTemplate);
        $.applyDataMask();
        Revolution.get("mde.accessible-function").giveTempFocus($wrapper.find('.js-added-line:last-child'));
    },

    conditionnalRadio: function (e) {
        var $obj = $(e.currentTarget);
        var group = $obj.data('fields-group');
        var targets = $obj.data('fields');
        var targetsArray = targets.split(', ');

        $(group).find('.js-conditional').hide();
        $(group).find('.js-conditional').find('[data-required]').removeAttr('required');

        for (i = 0; i < targetsArray.length; i++) {
            var target = targetsArray[i];
            $(target).show();
            $(target).find('[data-required]').attr('required', true);
        }
    },

    initInputFile: function () {
        $('.js-inputfile, .wpcf7-form-control-wrap input[type="file"]').each(function (index, el) {

            if ($(el).closest('.wpcf7-form-control-wrap').length) {
                var $label = $(el).closest('.wpcf7-form-control-wrap').next('label');
                var name = $(el).attr('name');
                $(el).attr('id', name);
                $label.attr('for', name);
            } else {
                var $label = $(el).next('label');
            }
            var labelVal = $label.html(),
                $formItem = $label.closest('.form-item, .wpcf7-form-control-wrap');

            $(el).on('change', function (e) {
                var fileName = '';
                // if (this.files && this.files.length > 1) {
                //     fileName = (this.getAttribute('data-multiple-caption') || '').replace('{count}', this.files.length);
                // } else {
                //     //fileName = e.target.value.split("\"").pop();

                // }
                fileName = e.target.files[0].name;
                console.log(fileName);

                if (fileName) {
                    $label.find('.result').text(fileName);
                    $formItem.find('.delete-inputfile').show();
                } else {
                    $label.text(labelVal);
                }
            });
        });
    },
    showInputFile: function () {

    },
    clearInputFile: function (e) {
        var $obj = $(e.currentTarget);
        $obj.closest('label').prev('[type="file"]').val('');
        $obj.closest('label').find('.result').text('');
        $obj.hide();
    },

    checkboxesCounter: function (e) {

        var $obj = $(e.currentTarget);
        var $label = $(e.currentTarget).next('label');
        var $parent = $obj.closest('.bk-options');
        var nbChecked = $parent.find(':checked').length;

        if (nbChecked > 0) {
            $parent.next('.ta-c').find(':checked').prop('checked', false);
        }

        if ($obj.is(':checked')) {
            $label.find('.label-number').html(nbChecked);
        } else {
            var valDisable = parseInt($label.find('.label-number').html());
            $parent.find(':checked').each(function (index, elem) {
                var val = parseInt($(elem).next('label').find('.label-number').html());
                if (val > valDisable) {
                    $(elem).next('label').find('.label-number').html(val - 1);
                }
            });
        }
    },

    uncheckOptions: function (e) {
        var $obj = $(e.currentTarget);
        $obj.closest('.ta-c').prev('.bk-options').find(':checked').prop('checked', false);
    },

    setDatePicker: function () {
        $('.date-placeholder').each(function (index, elem) {
            var datepicker = $(elem);

            datepicker
                .datepicker({
                    format: 'yyyy-mm-dd',
                    language: ($('html').attr('lang') === "fr") ? 'fr-FR' : 'en-GB'
                })
                .mask("0000/00/00", { placeholder: "yyyy/mm/dd" })
                .on("change", function() {
                    var splitedDate = $(this).val().split('/');
                    var dateFr = splitedDate[2]+'/'+splitedDate[1]+'/'+splitedDate[0];
                    $(this).next('.js-date-placeholderfr').val(dateFr);
                });

            datepicker.next('.js-date-placeholderfr')
                .mask("00/00/0000", { placeholder: "jj/mm/aaaa" })
                .on("click", function() {
                    setTimeout(function() {
                        datepicker.datepicker('show');
                    }, 200);
                })
                .on("blur", function() {
                    $(this).prev('.js-date-placeholder').val('');
                });
        });

    }
});
