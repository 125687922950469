Revolution.Module.extend({
    NAME: "mde.animations", // OBLIGATOIRE (reflète l'arborescence répertoire : /mde/main.js)
    controller: null,

    initialize: function ($) {
        // appelé aussitôt la page chargée
        //console.log("module " + this.NAME);

        //_.bindAll(this, "myfunction", "runEqualHeight", "getHeightsForOffsets");

        //Example to call an external function
        //var UTILS = Revolution.get("mde._utils");
        //UTILS.equalheight($('.bk-mosaique .js-equal-height'));


        $('.bk-floatings-btns a').on('mouseenter', function () {
            var w = $(this).find('.text-item').outerWidth();
            gsap.to($(this).find('.text-mask'), { duration: .3, width: w, ease: 'power4.out' });
        });
        $('.bk-floatings-btns a').on('mouseleave', function () {
            gsap.to($(this).find('.text-mask'), { duration: .3, width: 0, ease: 'power4.out' });
        });

        this.initAnimation();


        this.controller = new ScrollMagic.Controller();
        var _THIS = this;

        // hero
        $('[class^="hero-"]').each(function (index, elem) {
            var $img = $(elem).find('.img-wrapper img');
            var height = $(elem).outerHeight();

            var tween = gsap.timeline()
                .to($img, { duration: 1, y: height/2, ease: 'none' })

            var scene = new ScrollMagic.Scene({
                triggerElement: elem
                ,triggerHook: 0
                ,duration: height
            })
            .setTween(tween)
            .addTo(_THIS.controller);
        });

        // hero-edito
        $('.hero-edito').each(function (index, elem) {
            var $txt = $(elem).find('.hero-title, .intro, .desc, .date');

            var tween = gsap.timeline()
                .from($txt, { duration: 1, opacity: 0, x: 200, ease: 'power4.out', stagger: 0.2 })

            var scene = new ScrollMagic.Scene({
                triggerElement: elem
                ,triggerHook: 1
                //,duration: '200px'
            })
            .setTween(tween)
            .addTo(_THIS.controller);
        });
        $('.hero-edito').each(function (index, elem) {
            var $txt = $(elem).find('.half-part');
            var height = $(elem).outerHeight();

            var tween = gsap.timeline()
                .to($txt, { duration: 1, color: 'rgba(255,255,255,0)', ease: 'none' })

            var scene = new ScrollMagic.Scene({
                triggerElement: elem
                ,triggerHook: 0
                ,duration: height
            })
            .setTween(tween)
            .addTo(_THIS.controller);
        });

        // hero-dispatch
        $('.hero-dispatch .bottom-hero').each(function (index, elem) {
            var $txt = $(elem).find('.intro');
            var height = $(elem).outerHeight();
            var $trigger = $(elem).closest('.hero-dispatch').find('.circle-mask');

            var tween = gsap.timeline()
                .to($txt, { duration: 1, color: 'rgba(255,255,255,0)', ease: 'none' })

            var scene = new ScrollMagic.Scene({
                triggerElement: $trigger
                ,triggerHook: 0
                ,duration: height
            })
            .setTween(tween)
            //.addIndicators({name: 'bottom hero'})
            .addTo(_THIS.controller);
        });
        $('.hero-dispatch').each(function (index, elem) {
            var $txt = $(elem).find('.hero-title, .intro');

            var tween = gsap.timeline()
                .from($txt, { duration: 1, opacity: 0, x: 200, ease: 'power4.out', stagger: 0.2 })

            var scene = new ScrollMagic.Scene({
                triggerElement: elem
                ,triggerHook: 1
                //,duration: '200px'
            })
            .setTween(tween)
            .addTo(_THIS.controller);
        });

        // sumary && annexes
        $('.bk-summary, .bk-annexe').each(function (index, elem) {
            var $title = $(elem).find('.title');
            var $li = $(elem).find('li');

            var tween = gsap.timeline()
                .from($title, { duration: 1, opacity: 0, ease: 'power4.out' })
                .from($li, { duration: 1, opacity: 0, x: 200, ease: 'power4.out', stagger: 0.2 }, '-=0.5')

            var scene = new ScrollMagic.Scene({
                triggerElement: elem
                ,triggerHook: 1
                //,duration: '200px'
            })
            .setTween(tween)
            .addTo(_THIS.controller);
        });


        // bk-mention
        $('.bk-mention').each(function (index, elem) {
            var $text = $(elem).find('> *');

            var tween = gsap.timeline()
                .from($text, { duration: 1, opacity: 0, y: 100, ease: 'power4.out', stagger: 0.2 })

            var scene = new ScrollMagic.Scene({
                triggerElement: elem
                ,triggerHook: 1
                //,duration: '200px'
            })
            .setTween(tween)
            .addTo(_THIS.controller);
        });
        
        // bk-promote
        $('.bk-promote').not('.-sm').each(function (index, elem) {
            var $txt = $(elem).find('.content-wrapper > *');

            var tween = gsap.timeline()
                .from($txt, { duration: 1, opacity: 0, x: '150px', ease: 'power4.out', stagger: 0.2 })

            var scene = new ScrollMagic.Scene({
                triggerElement: elem
                ,triggerHook: 0.8
                ,reverse: false
                //,duration: '100%'
            })
            .setTween(tween)
            .addTo(_THIS.controller);
        });

        $('.bk-promote.-sm').each(function (index, elem) {
            var $txt = $(elem).find('.content-wrapper > *');

            var tween = gsap.timeline()
                .from($txt, { duration: 1, opacity: 0, y: '150px', ease: 'power4.out', stagger: 0.2 })

            var scene = new ScrollMagic.Scene({
                triggerElement: elem
                ,triggerHook: 0.5
                ,reverse: false
                //,duration: '100%'
            })
            .setTween(tween)
            .addTo(_THIS.controller);
        });

        $('.site-content.-main .grid').each(function (index, elem) {
            var $col = $(elem).find('.bk-promote.-sm');
            var $card = $(elem).find('.bk-card');

            if ($col.length) {
                var tween = gsap.timeline()
                    .from($col, { duration: 1, opacity: 0, y: '100px', ease: 'power4.out', stagger: 0.3 })

                var scene = new ScrollMagic.Scene({
                    triggerElement: elem
                    ,triggerHook: 0.8
                    ,reverse: false
                    //,duration: '100%'
                })
                .setTween(tween)
                .addTo(_THIS.controller);
            }

            if ($card.length) {
                var tween = gsap.timeline()
                    .from($card, { duration: 1, opacity: 0, y: '100px', ease: 'power4.out', stagger: 0.3 })

                var scene = new ScrollMagic.Scene({
                    triggerElement: elem
                    ,triggerHook: 0.8
                    ,reverse: false
                    //,duration: '100%'
                })
                .setTween(tween)
                .addTo(_THIS.controller);
            }
        });

        // bk img-text
        $('.bk-img-text').each(function (index, elem) {
            var $img = $(elem).find('.img-wrapper');
            var $text = $(elem).find('.text-wrapper');
            var $childs = $(elem).find('> *');

                var tween = gsap.timeline()
                    .from($childs, { duration: 1, opacity: 0, y: '100px', ease: 'power4.out', stagger: 0.3 })

                var scene = new ScrollMagic.Scene({
                    triggerElement: elem
                    ,triggerHook: 0.8
                    ,reverse: false
                    //,duration: '100%'
                })
                .setTween(tween)
                .addTo(_THIS.controller);
        });

        //Bk discover

        $('.bk-discover').each(function (index, elem) {
            var height = $(elem).height();
            var tween = gsap.timeline()
                .from($(elem).find('.text-big'), { duration: 1, y: '500px', ease: 'power2.out' })

            var scene = new ScrollMagic.Scene({
                triggerElement: elem
                ,triggerHook: 0.5
                ,duration: '200%'
            })
            .setTween(tween)
            .addTo(_THIS.controller);
        });

        $('.bk-discover').each(function (index, elem) {
            var tween = gsap.timeline()
                .to($('body'), { duration: .5, backgroundColor: '#460439', ease: 'power2.out' })

            var scene = new ScrollMagic.Scene({
                triggerElement: elem
                ,triggerHook: 0.5
                //,duration: '500px'
            })
            .setTween(tween)
            .addTo(_THIS.controller);
        });
        
        $('.bk-discover').next().each(function (index, elem) {
            var tween = gsap.timeline()
                .to($('.global-wrapper'), { duration: .5, backgroundColor: '#F1F7F7', ease: 'power2.out' })

            var scene = new ScrollMagic.Scene({
                triggerElement: elem
                ,triggerHook: 0.5
                //,duration: '200px'
            })
            .setTween(tween)
            .addTo(_THIS.controller);
        });
        
        $('.wrapper-masks .mask').each(function (index, elem) {
            var tween = gsap.timeline()
                .from($(elem).find('.circle-mask'), { duration: 0.3, x: '-50%', opacity: 0, ease: 'power2.out' })
                .from($(elem).find('.d-arrow'), { duration: 0.3, x: '-100px', opacity: 0, ease: 'power2.out' }, '-=.2')
                .from($(elem).find('.txt-wrapper'), { duration: 0.5, x: '-5px', opacity: 0, ease: 'power2.out' })

            var scene = new ScrollMagic.Scene({
                triggerElement: elem
                ,triggerHook: 0.8
                //,reverse: false
                //,duration: '500px'
            })
            .setTween(tween)
            .addTo(_THIS.controller);
        });

        // Tableaux
        $('.table.table-striped.custom-table tr').each(function (index, elem) {
            var tween = gsap.timeline()
                .from($(elem), { duration: 1, opacity: 0, y: '25%', ease: 'power2.out' })

            var scene = new ScrollMagic.Scene({
                triggerElement: elem
                ,triggerHook: 0.9
                //,duration: '100%'
            })
            .setTween(tween)
            .addTo(_THIS.controller);
        });

        // Restez informés
        $('.bk-stay-informed').each(function (index, elem) {
            var tween = gsap.timeline()
                .to($(elem).find('.img-wrapper img'), { duration: 1, y: '25%', ease: 'power2.inOut' })

            var scene = new ScrollMagic.Scene({
                triggerElement: elem
                ,triggerHook: 0.8
                ,duration: '100%'
            })
            .setTween(tween)
            .addTo(_THIS.controller);
        });

        $('.bk-stay-informed').each(function (index, elem) {
            var $titleTop = $(elem).find('.title.-top');
            var mySplitText = new SplitText($titleTop, { type: "chars, lines" })
            var tween = gsap.timeline({ onComplete: killText })
                .set(mySplitText.lines, { overflow: 'hidden' })
                .from(mySplitText.chars, { duration: 0.5, y: '100%', ease: 'back.out(1.2)', stagger: 0.05 })

            function killText() {
                //mySplitText.revert();
            }

            var scene = new ScrollMagic.Scene({
                triggerElement: elem,
                triggerHook: .9
                //,reverse: false
            })
                .setTween(tween)
                //.addIndicators({name: 'titles'})
                .addTo(_THIS.controller);
        });

        //parallax img
        $('.page-section-img').each(function (index, elem) {
            var gap = 200;
            if ($(elem).attr('data-move')) {
                gap = parseInt($(elem).data('move'));
            }
            var $wrapper = $(elem).parent();
            var heightWrapper = $wrapper.outerHeight();
            var windowH = $(window).height();
            var tween = gsap.timeline()
                .set(elem, { top: gap * -1, height: 'calc(100% + ' + (gap * 2) + 'px)' })
                .fromTo(elem, { duration: 1, y: (gap * -1), ease: 'none' }, { duration: 1, y: gap, ease: 'none' })

            var scene = new ScrollMagic.Scene({
                triggerElement: $wrapper,
                triggerHook: 1,
                duration: windowH + heightWrapper
            })
                .setTween(tween)
                //.addIndicators({name: 'section bg '+index})
                .addTo(_THIS.controller);
        });

    },

    onWindowLoad: function (e) {
        //
    },
    onWindowScroll: function (e) {

    },
    onWindowResize: function (e) {

    },
    initAnimation: function (e) {
        var headerTitle = $('.hero-xl .hero-title');
        var headerImg = $('[class^="hero-"] .img-wrapper img');
        var buttons = $('.bk-floatings-btns li');

        var tl = gsap.timeline();

        tl.from(headerTitle,
            { duration: 1, y: 30, opacity: 0, ease: Power1.inOut }
        );

        tl.from(headerImg,
            { duration: 1, scale: 1.2, ease: Power1.inOut },
            0
        );

        tl.from(buttons,
            { duration: .2, x: '150%', opacity: 0, ease: Power1.out, stagger: 0.1 }, '+=.5'
        );
    }

});
