Revolution.Module.extend({
    NAME: "mde.tables", // OBLIGATOIRE (reflète l'arborescence répertoire : /mde/main.js)

    initialize: function ($) {
        // appelé aussitôt la page chargée
        //console.log("module " + this.NAME);

        $('body').on('click', 'tr[data-href]', this.linkToTRElement);

        shave('.td-text, .td-mention', 55);

        $('.table-responsive .dataTables_paginate').on('click', this.paginateOnClick)

    },

    onWindowLoad: function (e) {
        //
    },
    onWindowScroll: function (e) {
        //
    },
    onWindowResize: function (e) {
        //
    },
    linkToTRElement: function (e) {
        var $obj = $(e.currentTarget);
        var getElementLink = $obj.data('href');
        var checkH3C = (/h3c./).test(getElementLink);
        var checkPDF = (/.pdf/).test(getElementLink);

        if (checkPDF === true) {
            window.open(getElementLink, '_blank');
        } else if (checkH3C === true) {
            window.open(getElementLink, "_self");
        } else {
            window.open(getElementLink, '_blank');
        }
    },

    paginateOnClick: function () {
        setTimeout(function () {
            shave('.td-text, .td-mention', 55);
        }, 100);

        $('html, body').animate({
            scrollTop: $(".bk-breadcrumb").offset().top
        }, 100);
    }
});