Revolution.Module.extend({
    NAME: "mde.bk-summary", // OBLIGATOIRE (reflète l'arborescence répertoire : /mde/main.js)

    initialize: function ($) {
        // appelé aussitôt la page chargée
        //console.log("module " + this.NAME);

        $('body').on('click', '.js-summary li a', this.activeClassSummary);
    },

    onWindowLoad: function (e) {
        //
    },
    onWindowScroll: function (e) {
        //
    },
    onWindowResize: function (e) {
        //
    },
    activeClassSummary: function (e) {
        var $obj = $(e.currentTarget);

        $obj.closest('ul').find('li a').removeClass('active');
        $obj.addClass('active');
    }
});