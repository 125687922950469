Revolution.Module.extend({
    NAME: "mde.nav", // OBLIGATOIRE (reflète l'arborescence répertoire : /mde/main.js)

    initialize: function ($) {
        // appelé aussitôt la page chargée

        //console.log("module " + this.NAME);

        _.bindAll(this, "closeNavMob", "showBtnExpendTouch");
        //
        this.initEnquireResetStyles();

        //events


        //Open submenu headerSup
        $('body').on('click', '.js-toggle-sub-menu', this.toggleSubMenu);
        $('body').on('click', '.js-close-sub-menu', this.closeSubMenus);
        $('body').click(this.closeSubMenus);

        $('body').on('click', '.js-toggle-sub-menu, .sub-menu', function (e) {
            e.stopPropagation();
        });


        $('.js-toggle-mob-nav').on('click', this.toggleMobileMenu);

        $('.main-nav .li-level-1').on('mouseenter', this.navMouseEnter);
        $('.main-nav .li-level-1').on('mouseleave', this.navMouseLeave);

        $('.main-nav .show-more').on('click', this.toggleLevel2);
        $('.js-close-nav-mob').on('click', this.closeNavMob);

        if (Modernizr.touchevents) {
            this.showBtnExpendTouch();
        }

        $('body').on('click', '.js-toggle-search', this.toggleSearchHeader);

    },

    onWindowLoad: function (e) {

    },

    onWindowScroll: function (e) {

    },
    onWindowResize: function (e) {
    },

    closeSubMenus: function (e) {
        $('.sub-menu:visible').slideUp().prev('.js-toggle-sub-menu').removeClass('active').attr('aria-expanded', "false");
    },

    toggleSubMenu: function (e) {
        var $obj = $(e.currentTarget);
        var $subMenu = $obj.next('.sub-menu');
        if (!$obj.hasClass('active')) {
            $('.page-header-wrapper .sub-menu:visible').hide();
            $subMenu.slideDown();
            $obj.addClass('active').attr('aria-expanded', 'true');
        } else {
            $subMenu.slideUp();
            $obj.removeClass('active').attr('aria-expanded', 'false');
        }
    },


    navMouseEnter: function (e) {
        var UTILS = Revolution.get("mde._utils");
        $obj = $(e.currentTarget);
        if (UTILS.isMenuDesktop() && !Modernizr.touchevents) {
            $obj.addClass('active');
            if ($('.level-2-wrapper:visible').length) {
                $obj.find('.level-2-wrapper').addClass('open').stop().show();
            } else {
                $obj.find('.level-2-wrapper').addClass('open').stop().fadeIn();
            }
        }
    },
    navMouseLeave: function (e) {
        $obj.removeClass('active');
        var UTILS = Revolution.get("mde._utils");
        $obj = $(e.currentTarget);
        if (UTILS.isMenuDesktop() && !Modernizr.touchevents) {
            $obj.find('.level-2-wrapper').removeClass('open').stop().fadeOut(10);
        }
    },

    reinitMobileMenu: function () {
        $('body, header [style]').removeAttr('style');
        $('header .open').removeClass('open');
    },

    initEnquireResetStyles: function () {
        enquire.register("screen and (min-width:79.9375em)", { //1279px
            match: _.bind(function () {
                this.reinitMobileMenu();
            }, this),
            unmatch: _.bind(function () {
                this.reinitMobileMenu();
            }, this)
        });
    },

    toggleMobileMenu: function (e) {
        e.preventDefault();
        $obj = $(e.currentTarget);
        $mobileMenu = $('.js-toggle-mob-nav')
        $mobileNav = $('.header-right');

        if ($mobileMenu.hasClass('open')) {
            $mobileMenu.removeClass('open').attr('aria-expanded', 'false');
            $mobileNav.stop().fadeOut();
            $('html, body').css('overflow', '');
            $('.mob-icons-wrapper').show();

            // Remove calculated heights
            $mobileNav.find('.lists-wrapper').removeAttr('style');
            $mobileNav.find('.main-nav').removeAttr('style');
        } else {
            $mobileMenu.addClass('open').attr('aria-expanded', 'true');
            $mobileNav.stop().fadeIn();
            $('html, body').css('overflow', 'hidden');
            $('.mob-icons-wrapper').hide();

            // Calculate height for menu on mobile (to handle browser toolbars)
            setTimeout(function() {
                $mobileNav.find('.lists-wrapper').css('height', window.innerHeight+'px');
                $mobileNav.find('.main-nav').css('height', 'calc(100vh - '+$mobileNav.find('.header-sup').css('height')+')');
            }, 200);
        }
    },

    toggleLevel2: function (e) {
        // e.preventDefault();
        $obj = $(e.currentTarget);
        $currentLi = $obj.closest('li');
        $currentLevel2 = $currentLi.find('.level-2-wrapper');
        $mobileNav = $('.header-right');
        var UTILS = Revolution.get("mde._utils");

        if (UTILS.isMenuMobile()) { //MOBILE

            if ($currentLi.hasClass('open')) { // BACK TO LEVEL 1
                $obj.removeClass('open');
                $currentLi.removeClass('open');
                $currentLevel2.stop().slideUp();
                $mobileNav.find('.li-level-1').not(':visible').stop().slideDown();
            } else { // GO TO LEVEL 2
                $('.main-nav .show-more').removeClass('open');
                $obj.addClass('open');
                $mobileNav.find('.li-level-1').removeClass('open');
                $mobileNav.find('.level-2-wrapper').stop().slideUp();

                $currentLi.addClass('open');
                $currentLevel2.stop().slideDown();
                // $mobileNav.find('.li-level-1').not('.open').stop().slideUp();
            }

        } else if (Modernizr.touchevents) { //DESKTOP & TOUCH

            if ($currentLevel2.hasClass('open')) { //close
                $currentLi.removeClass('active');
                $currentLi.find('.level-2-wrapper').removeClass('open').stop().fadeOut();
            } else {// open
                //close opened nav
                $mobileNav.find('.active').removeClass('active');
                $mobileNav.find('.open').removeClass('open');
                $mobileNav.find('.level-2-wrapper').stop().fadeOut();

                //open current
                $currentLi.addClass('active');
                $currentLi.find('.level-2-wrapper').addClass('open').stop().fadeIn();

            }
        }

    },

    closeNavMob: function (e) {
        var $navMob = $('.header-right');
        $navMob.fadeOut(_.bind(function () {
            //reset opened menu
            this.reinitMobileMenu();
        }, this));
    },

    showBtnExpendTouch: function () {
        $('.page-header-wrapper .main-nav .li-level-1 .btn-expend').show();
    },

    toggleSearchHeader: function () {
        var $moduleSearch = $('.module-search');
        var $moduleOpacity = $('.module-opacity');

        $moduleSearch.toggleClass('open');
        $moduleOpacity.addClass('open');

        if ($moduleSearch.hasClass('open')) {
            $moduleSearch.find('input').attr("tabindex", '0').attr('aria-hidden', 'false');
            $moduleSearch.find('a').attr("tabindex", '0').attr('aria-hidden', 'false');
            $moduleSearch.find('.close-btn').attr("tabindex", '0').attr('aria-hidden', 'false');


        } else {
            $moduleSearch.find('input').attr("tabindex", '-1').attr('aria-hidden', 'true');
            $moduleSearch.find('a').attr("tabindex", '-1').attr('aria-hidden', 'true');
            $moduleSearch.find('.close-btn').attr("tabindex", '-1').attr('aria-hidden', 'true ');
            $moduleOpacity.addClass('closing');

            setTimeout(function () {
                $moduleOpacity.removeClass('open closing')
            }, 1000)
        }
    }
});
