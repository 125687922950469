Revolution.Module.extend({
    NAME: "mde.bk-list-offers", // OBLIGATOIRE (reflète l'arborescence répertoire : /mde/main.js)

    initialize: function ($) {

        $('body').on('click', '.js-accordion', this.toggleAccordion);
    },

    onWindowLoad: function (e) {

    },

    onWindowScroll: function (e) {

    },
    onWindowResize: function (e) {

    },
    toggleAccordion: function (e) {
        var $obj = $(e.currentTarget);
        var $offerWrapper = $obj.next('.accordion-content');

        if ($obj.attr('tabindex') != '-1') {
            $obj.toggleClass('open');
            $offerWrapper.stop().slideToggle();

            if ($obj.hasClass('open')) {
                $obj.attr('aria-expanded', "true");
            } else {
                $obj.attr('aria-expanded', "false");
            }
        }
    }
});
