Revolution.Module.extend({
    NAME: "mde.sliders", // OBLIGATOIRE (reflète l'arborescence répertoire : /mde/main.js)

    initialize: function ($) {
        // appelé aussitôt la page chargée
        //console.log("module " + this.NAME);

        //_.bindAll(this, "equalheight", "runEqualHeight", "getHeightsForOffsets");

        //events
        this.initSwiper();
    },

    onWindowLoad: function (e) {

    },

    onWindowScroll: function (e) {

    },
    onWindowResize: function (e) {

    },
    initSwiper: function () {
        $('.bk-slider').each(function (index, elem) {
            var swiper = new Swiper($(elem), {
                pagination: {
                    el: '.swiper-pagination',
                },
            });
        });
    }
});
